.App {
  text-align: center;
}

.Canvas {
  object-fit: contain;
  box-sizing: border-box;
  border: 10px solid red;
  height: 100%;
  width: 100%;
  max-width: 100%;
};

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
